import React from "react"
import { navigateTo } from "gatsby-link"
import Typography from "@material-ui/core/Typography"
import ContentLayout from "../components/ContentLayout"
import { StaticQuery } from "gatsby"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "DSC05483.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <ContentLayout
            title={"Kontakt"}
            description={"Wenn du bei uns mitmachen möchtest oder Fragen zu unserem Sport hast, kannst du dich gerne über dieses Formular bei uns melden."}
            keywords={["kontakt"]}

            image={data.placeholderImage.childImageSharp.fluid}
            url={"/kontakt"}
          >

            <Typography variant="h2" component="h1" gutterBottom>
              Kontakt
            </Typography>

            <form
              name="contact"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact"  />

              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>

              <TextField
                type="text"
                name="name"
                onChange={this.handleChange}
                fullWidth
                margin="normal"
                label="Name"
                required
              />

              <TextField
                type="email"
                name="email"
                onChange={this.handleChange}
                fullWidth
                margin="normal"
                label="Email"
                required
              />

              <TextField
                type="text"
                name="subject"
                onChange={this.handleChange}
                fullWidth
                margin="normal"
                label="Betreff"
                required
              />

              <TextField
                type="text"
                name="message"
                onChange={this.handleChange}
                fullWidth
                margin="normal"
                label="Nachricht"
                multiline
                rows={3}
                required
              />

              <Button
                type="submit"
                color="primary"
                fullWidth
                margin="normal"
                variant="contained"
              >
                Senden
              </Button>
            </form>
          </ContentLayout>
        )
        }
      />

    );
  }
}
